@charset "UTF-8";

// ----------------------------------------------
.card-collapse {
  $card-collapse-btn-color: #646464;
  $card-collapse-btn-color-hover: #275cc4;
  $card-collapse-btn-bg-color: #1946ba;
  $card-collapse-btn-bg-color-hover: $card-collapse-btn-color-hover;
  $card-collapse-open-btn-color: $card-collapse-btn-color;
  $card-collapse-open-btn-color-hover: $card-collapse-btn-color-hover;
  $card-collapse-open-btn-bg-color: #999;
  $card-collapse-open-btn-bg-color-hover: #a8a8a8;

  margin-bottom: 0;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  &.border-top {
    border-top: 1px solid #1946ba;
  }

  .card-body {
    padding: 0;
    margin-bottom: 15px;
  }

  .card-title {
    padding: 0;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #1946ba;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    a {
      position: relative;
      display: block;
      padding: 22px 50px 23px 20px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
      color: $card-collapse-btn-color;
      text-decoration: none;

      @include media-breakpoint-down(sm) {
        padding: 12px 65px 10px 15px;
        font-size: 16px;
      }

      &:hover,
      &:focus,
      &[aria-expanded="true"] {
        color: $card-collapse-btn-color-hover;
        text-decoration: none;

        .icon {
          background-color: $card-collapse-btn-bg-color-hover;
        }
      }

      &[aria-expanded="true"] {
        color: $card-collapse-open-btn-color;
        background-color: #f4f5f7;

        &:hover {
          color: $card-collapse-btn-color-hover;

          .icon {
            background-color: $card-collapse-open-btn-bg-color-hover;
          }
        }

        .icon {
          background-color: $card-collapse-open-btn-bg-color;

          .bar {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .card-text-detail {
    padding: 5px 100px 21px 20px;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      padding: 5px 15px 20px;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: block;
    width: 22px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: $card-collapse-btn-bg-color;
    content: "";
    transition: 300ms all ease;

    @include media-breakpoint-down(sm) {
      right: 10px;
    }
  }

  .bar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 2px;
    height: 12px;
    margin: auto;
    background-color: #fff;
    content: "";
    transition: 300ms all ease;

    &:nth-child(2n) {
      transform: rotate(90deg);
    }
  }
}

// ----------------------------------------------
@mixin bg-switchboard {
  background-image: url("../img/common/bg-switchboard.jpg");
}

// ----------------------------------------------
a {
  &.card {
    @include transition(all $hover-transition-speed ease);
  }
}

// ----------------------------------------------
.card-bg-multiply {
  max-width: 480px;
  padding: 0;
  margin-right: auto;
  margin-left: auto;

  .card-body {
    position: relative;
    padding: 0;
  }

  .card-title {
    position: relative;
    z-index: 1;
    padding: 16px 5px 13px;
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    text-align: center;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-position: 50% 0;
      content: "";
      background-blend-mode: multiply;
    }

    &.bg-light-orange {
      &:after {
        background-color: #d47d12;
      }
    }
  }

  .bg-switchboard {
    @include bg-switchboard();

    .card-title {
      &:after {
        @include bg-switchboard();
      }
    }
  }

  .img {
    height: 147px;
    background-position: 50% 0;
    background-repeat: no-repeat;
  }
}

// ----------------------------------------------
.card-contact {
  @include box-shadow (none);
  color: #222;
  border: 0;
  border-radius: 0;

  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }

  .card-title {
    margin-bottom: 0;
  }

  .both-side-slash {
    position: relative;
    display: inline-block;
    padding-right: 56px;
    padding-left: 56px;
    margin-top: 5px;
    text-align: center;

    &:after,
    &:before {
      top: -8px;
      bottom: 0;
      width: 26px;
      height: 34px;
    }

    &:before {
      background: url("../img/common/slash-left-white.png") no-repeat;
    }

    &:after {
      background: url("../img/common/slash-right-white.png") no-repeat;
    }
  }

  .card-header {
    text-align: center;
    background-color: #c83737;
    border-bottom: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    @include media-breakpoint-down(sm) {
      padding-top: 9px;
      padding-bottom: 6px;
    }
  }

  .card-body {
    padding: 15px 15px 20px;
    border: 7px solid #b9b9b9;
    border-top: 0;

    @include media-breakpoint-down(sm) {
      padding: 4px 4px 0;
      border-width: 4px;
    }
  }

  .title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.1;
    color: #fff;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  .inner {
    @include clearfix ();
    max-width: 820px;
    margin-right: auto;
    margin-left: auto;
  }

  .lead {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: $lead-font-weight;
    letter-spacing: -1px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      text-align: center;
    }
  }

  .hours {
    display: block;
    margin-top: 5px;
    margin-left: 67px;
    font-size: 26px;
  }

  .img {
    float: right;
    margin-top: -74px;
  }

  .body {
    @include media-breakpoint-up(sm) {
      float: left;
    }
  }

  .btn-container {
    max-width: 854px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    a {
      @include box-shadow (0 -1px 3px 0 rgba(0, 0, 0, .3));
      display: block;
      border-radius: 24px;
    }
  }

  .btn-tel-sm {
    margin-bottom: 4px;
  }

  .media {
    max-width: 304px;
    margin-right: auto;
    margin-left: auto;
  }

  .media-right {
    padding-left: 0;

    img {
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

// ----------------------------------------------
.card {
  &.border-radius-base {
    border-radius: $border-radius;
  }

  &.card-title-on-border {
    position: relative;
    padding-top: 35px;

    .title {
      position: absolute;
      top: -.75em;
      right: 0;
      left: 0;
      padding-right: 1.5%;
      padding-left: 1.5%;
      margin: 0;

      .bg {
        display: inline-block;
        padding: 3px 12px;
        line-height: 1.1;
        background: #fff;
        border: 1px solid #000;
      }
    }
  }
}

// ----------------------------------------------
.card-overview {
  padding: 30px 26px 30px 58px;
  margin-bottom: 50px;
  color: #1e374e;
  background: #d8dcdf;
  border: 0;

  @include media-breakpoint-down(md) {
    padding: 20px;
    margin-bottom: 25px;
  }

  .card-left {
    @include media-breakpoint-up(md) {
      padding-right: 40px;
      border-right: 1px solid #1e374e;
    }

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid #1e374e;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 10px;
    }
  }

  .card-body {
    padding-left: 26px;

    @include media-breakpoint-down(md) {
      padding-top: 10px;
    }
  }

  .dl-list-indent {
    +.dl-list-indent {
      margin-top: 7px;
    }

    dt {
      min-width: 100px;
      font-weight: bold;

      @include media-breakpoint-down(md) {
        min-width: 80px;
      }
    }

    dd {
      line-height: 1.563;
    }
  }
}

// ----------------------------------------------
.card-has-ribbon {
  background: #fff;

  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }

  .ribbon {
    padding-right: 30px;
    padding-bottom: 7px;
    background-color: #fff;

    @include media-breakpoint-down(md) {
      padding-right: 15px;
    }

    &.blue {
      dt {
        background-color: #1f89ca;
      }
    }

    .dl-list-indent {
      margin-left: -28px;
      overflow: visible;

      dt,
      dd {
        vertical-align: middle;
      }

      dt {
        position: relative;
        min-width: 110px;
        padding: 8px 3px 6px;
        color: #fff;
        text-align: center;
        border-radius: 0;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }

        &:before {
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 0;
          height: 0;
          border-color: transparent #03263b transparent transparent;
          border-style: solid;
          border-width: 0 12px 12px 0;
          content: "";
        }
      }

      dd {
        padding: 7px 14px 5px;
        font-weight: bold;
        color: #1f89ca;
        background-color: #d2e7f4;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }
  }
}

// ----------------------------------------------
.card-top-red {
  @include box-shadow (none);
  color: #222;
  border: 0;
  border-radius: 0;

  @include media-breakpoint-down(sm) {
    margin-bottom: 15px;
  }

  .card-body {
    padding: 0;
  }

  .card-title {
    padding: 9px 10px 2px;
    margin-bottom: 0;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.1;
    text-align: center;
    background-color: #c83737;
    border-bottom: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    @include media-breakpoint-down(sm) {
      padding-top: 9px;
      padding-bottom: 6px;
      font-size: 20px;
    }
  }

  .card-body-detail {
    padding: 15px 15px 20px;
    border: 7px solid #b9b9b9;
    border-top: 0;

    @include media-breakpoint-down(sm) {
      padding: 4px 4px 0;
      border-width: 4px;
    }
  }

  .both-side-slash {
    position: relative;
    display: inline-block;
    padding-right: 56px;
    padding-left: 56px;
    margin-top: 5px;
    margin-bottom: 0;
    color: #fff;
    text-align: center;

    &:after,
    &:before {
      top: -8px;
      bottom: 0;
      width: 26px;
      height: 34px;
    }

    &:before {
      background: url("../img/common/slash-left-white.png") no-repeat;
    }

    &:after {
      background: url("../img/common/slash-right-white.png") no-repeat;
    }
  }

  .inner {
    @include clearfix ();
    max-width: 820px;
    margin-right: auto;
    margin-left: auto;
  }

  .lead {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: $lead-font-weight;
    letter-spacing: -1px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      text-align: center;
    }
  }

  .hours {
    display: block;
    margin-top: 5px;
    margin-left: 67px;
    font-size: 26px;
  }

  .img {
    float: right;
    margin-top: -74px;
  }

  .body {
    @include media-breakpoint-up(sm) {
      float: left;
    }
  }

  .btn-container {
    max-width: 854px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    a {
      @include box-shadow (0 -1px 3px 0 rgba(0, 0, 0, .3));
      display: block;
      border-radius: 24px;
    }
  }

  .btn-tel-sm {
    margin-bottom: 4px;
  }
}

// ----------------------------------------------
.card-gray {
  background: #d8dcdf;
  border: 0;
  border-radius: 0;

  .card-body {
    padding: 10px;
  }
}
