@charset "UTF-8";

// ----------------------------------------------
.breadcrumb {
  > li {
    + li {
      &:before {
        padding: 0 0 0 5px;
        vertical-align: 1px;
      }
    }
  }
}
