@charset "UTF-8";

// ----------------------------------------------
html {
  // @include media-breakpoint-down(sm) {
  //   font-size: $font-size-base - 1;
  // }

  &.wf-active {
    body {
      visibility: visible;
    }
  }
}

// ----------------------------------------------
body {
  position: relative;// fix for parent <html> tag width is smaller than body tag.
  visibility: hidden; // for html.wf-active {}

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

// sans = without
// sans-serif = without 'serif'
// ----------------------------------------------
.sans-serif {
  font-family: $font-family-sans-serif;
}

.serif {
  font-family: $font-family-serif;
}

.cardo {
  font-family: $font-family-cardo;
}

// ----------------------------------------------
// .noto-sans-jp {
//   font-family: "Noto Sans", "Noto Sans JP", "Noto Sans Japanese", sans-serif;
//
//   &.fw-400 {
//     font-weight: 400;
//   }
//
//   &.fw-500 {
//     font-weight: 500;
//   }
//
//   &.fw-700 {
//     font-weight: 700;
//   }
// }

// ----------------------------------------------
a {
  &,
  img {
    @include transition(all $hover-transition-speed ease);
  }

  &.underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.hover {
    &:hover {
      @include media-breakpoint-up(sm) {
        opacity: .8;
      }
    }
  }

  &.hover-img {
    &:hover {
      img {
        @include media-breakpoint-up(sm) {
          opacity: .8;
        }
      }
    }
  }

  .img-responsive {
    display: inline-block;
  }

  &.tel {
    font-family: Times, sans-serif;
  }
}

// ----------------------------------------------
// For responsive
@mixin google-map-container {
  position: relative;
  height: 0;
  padding-top: 228px;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: none;
  }
}

.google-map-container {
  @include google-map-container();
}
