@charset "UTF-8";

// ----------------------------------------------
.section-concept {
  .flex-text {
    padding-right: 86px;

    @include media-breakpoint-down(xl) {
      padding-right: 12px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    .title-with-small {
      margin-bottom: 34px;

      @include media-breakpoint-down(xl) {
        margin-bottom: 20px;
      }

      @include media-breakpoint-down(lg) {
        margin-bottom: 10px;
      }

      span {
        @include media-breakpoint-down(lg) {
          font-size: 55px;
        }

        @include media-breakpoint-down(md) {
          font-size: 40px;
        }
      }

      small {
        @include media-breakpoint-down(lg) {
          font-size: 18px;
        }
      }
    }

    .heading {
      font-size: 19px;
      font-weight: 800;
      color: #fff;
      line-height: 1.75;
      letter-spacing: .08em;
      margin-bottom: 25px;

      @include media-breakpoint-down(xl) {
        margin-bottom: 10px;
        line-height: 1.6;
      }

      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
    }

    .text {
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      line-height: 1.85;
      letter-spacing: .04em;
      margin-bottom: 13px;

      @include media-breakpoint-down(xl) {
        margin-bottom: 10px;
        line-height: 1.65;
      }

      @include media-breakpoint-down(lg) {
        font-size: 12px;
        letter-spacing: 0;
      }
    }
  }

  .flex-img {
    width: 54.5%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

// ----------------------------------------------
.section-model {
  padding-top: 124px;
  padding-bottom: 115px;
  font-size: 14px;

  @include media-breakpoint-down(md) {
    padding-top: 40px;
    padding-bottom: 60px;
    font-size: 12px;
  }

  .section-title {
    margin-bottom: 23px;

    @include media-breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }

  .section-lead {
    margin-bottom: 58px;
    font-weight: 500;
    line-height: 1.857;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      line-height: 1.6;
    }
  }

  .item {
    + .item {
      margin-top: 115px;

      @include media-breakpoint-down(md) {
        margin-top: 60px;
      }
    }
  }

  .figure {
    display: block;
    margin-bottom: 0;

    .figure-img {
      display: block;
      margin: 0 auto -157px;

      @include media-breakpoint-down(md) {
        margin-bottom: -30px;
      }
    }

    .figure-caption {
      padding-top: 184px;
      padding-bottom: 42px;
      font-size: 28px;
      letter-spacing: .1em;
      color: #fff;
      background-color: #2a2a2a;

      @include media-breakpoint-down(md) {
        padding-top: 60px;
        padding-bottom: 30px;
        font-size: 24px;
      }

      .text-sm {
        margin-top: 9px;
        margin-bottom: 0;
        font-size: 16px;
        letter-spacing: .08em;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }
  }

  .point {
    display: flex;
    align-items: center;
    padding-top: 56px;
    padding-bottom: 56px;

    @include media-breakpoint-down(md) {
      display: block;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .point-title {
      flex: 1 0 0;
      font-size: 26px;
      line-height: 1.692;
      letter-spacing: .1em;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }

    .point-list {
      flex: 0 1 47%;
      padding-left: 36px;
      margin-left: 20px;
      border: solid #707070;
      border-width: 0 0 0 1px;

      @include media-breakpoint-down(lg) {
        padding-left: 20px;
      }

      @include media-breakpoint-down(md) {
        padding-top: 20px;
        padding-left: 0;
        margin-top: 10px;
        margin-left: 0;
        border-width: 1px 0 0;
      }

      .list-title {
        margin-bottom: 18px;
        font-size: 18px;
        letter-spacing: .08em;

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }

      .list-inner {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.857;

        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 1.6;
        }
      }
    }
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    .img {
      width: 31%;
      max-width: 332px;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        max-width: 45%;
      }
    }
  }

  .address {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 37px 20px;
    background-color: #2a2a2a;

    @include media-breakpoint-down(md) {
      display: block;
      padding: 15px;
    }

    .detail {
      flex: 1 0 0;

      @include media-breakpoint-up(md) {
        max-width: 424px;
      }
    }

    .map {
      flex: 1 0 auto;
      max-width: 336px;
      width: 336px;
      margin-right: 12px;
      margin-left: 78px;
      object-fit: cover;

      @include media-breakpoint-down(lg) {
        max-width: 280px;
        margin-right: 0;
        margin-left: 30px;
      }

      @include media-breakpoint-down(md) {
        margin: 25px auto 10px;
      }
    }
  }

  .btn-container {
    max-width: 334px;
    margin: 40px auto 56px;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
}

// ----------------------------------------------
.section-contact {
  background-color: #2a2a2a;
  padding: 52px 0 87px;

  @include media-breakpoint-down(md) {
    padding: 30px 0 45px;
  }

  .container-fluid {
    max-width: 1074px;
  }

  .title {
    font-size: 64px;
    color: #fff;
    text-align: center;
    letter-spacing: .08em;
    margin-left: -26px;
    margin-bottom: 39px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-bottom: 25px;
      font-size: 35px;
    }
  }

  .container {
    max-width: 720px;
  }

  .text {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin: 48px 0 15px 5px;
    letter-spacing: .11em;

    span {
      margin-left: 10px;
      letter-spacing: .12em;

      @include media-breakpoint-down(md) {
        margin-left: 0;
        display: block;
      }
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;
      font-size: 14px;
    }
  }

  .icon-tel {
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      max-width: 40px;
      margin-right: 15px;
    }
  }

  .tel {
    font-size: 50px;
    letter-spacing: .08em;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      font-size: 35px;
    }

    &:hover {
      opacity: .7;
    }
  }
}

// ---------------------------------------------
.section-works {
  background-color: #2a2a2a;
  padding: 65px 0;
  font-family: $font-family-serif;

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }

  .title-with-small {
    margin-bottom: 37px;
  }

  .row-24 {
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  .col-item {
    display: block;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    &:hover {
      opacity: .7;
    }

    img {
      &:hover {
        animation: unset;
      }
    }
  }

  .row-14 {
    .col-item {
      font-size: 16px;
      font-weight: bold;
      text-decoration: none;
      color: #fff;
      letter-spacing: .06em;

      > img {
        margin-bottom: 3px;

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

// ----------------------------------------------
.section-company {
  padding-block: 75px 117px;
  color: #fff;

  @include media-breakpoint-down(md) {
    padding-block: 50px 80px;
  }

  .container {
    max-width: 1100px;
  }

  .flex-img {
    @include media-breakpoint-up(md) {
      width: 24%;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
      margin-bottom: 15px;
    }

    img {
      width: 100%;

      @include media-breakpoint-down(md) {
        max-width: 200px;
      }
    }
  }

  .flex-text {
    @include media-breakpoint-up(md) {
      margin-left: 50px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 100px;
    }
  }

  .lead {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .08em;
    line-height: 2;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      line-height: 1.8;
    }
  }

  .caption {
    margin-block: 25px 0;
    font-size: 22px;
    letter-spacing: .14em;
    line-height: 1.9;

    @include media-breakpoint-down(md) {
      margin-top: 15px;
      font-size: 18px;
    }

    small {
      display: block;
      font-size: 16px;
      letter-spacing: .06em;
      line-height: 1.5;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }

  .inner {
    max-width: 853px;
    margin-inline: auto;
  }
}

// ----------------------------------------------
.section-fv {
  position: relative;

  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    margin-bottom: 0;
  }

  .img-fv {
    width: 100%;
  }
}

// ----------------------------------------------
.section-introduction {
  padding-top: 104px;
  padding-bottom: 110px;

  @include media-breakpoint-down(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .text {
    margin-bottom: 110px;
    font-size: 21px;
    letter-spacing: .08em;
    line-height: 1.9;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
      font-size: 3.3vw;
    }
  }
}

// ----------------------------------------------
.section-form {
  padding-top: 62px;
  padding-bottom: 53px;

  @include media-breakpoint-down(md) {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .title-section {
    margin-bottom: 47px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  .lead {
    margin-bottom: 44px;
    font-size: 18px;
    line-height: 1.444;
    letter-spacing: .08em;

    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 1.4;
    }
  }

  .form-container {
    max-width: 928px;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
    letter-spacing: .1em;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    .form-inner {
      max-width: 828px;
      margin-right: auto;
      margin-left: auto;
    }

    .form-control {
      padding: 7px 10px;
      font-size: 16px;
      color: #000;
      background-color: #ededed;
      border-radius: 0;

      &:not(:focus) {
        border-color: #b1b1b1;
      }

      &.form-control-md {
        @include media-breakpoint-up(md) {
          max-width: 323px;
        }
      }

      &.form-control-lg {
        @include media-breakpoint-up(md) {
          max-width: 640px;
        }
      }
    }

    .form-check-input {
      margin-top: 5px;
      background-color: transparent;
      border-color: #000;
      border-radius: 0 !important;

      &:checked {
        background-color: #000;
      }
    }

    .form-check {
      margin-top: 45px;
      margin-bottom: 75px;

      @include media-breakpoint-down(md) {
        margin-top: 25px;
        margin-bottom: 35px;
      }

      .form-check-label {
        margin-left: 10px;

        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }
    }

    .form-caption {
      margin-bottom: 24px;

      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    }

    .required {
      color: #c61313;
    }
  }

  hr {
    border-top: 1px solid #707070;
    opacity: 1;
  }
}
