@charset "UTF-8";

// ----------------------------------------------
.title-with-small {
  margin-bottom: 60px;
  font-size: 72px;
  letter-spacing: .08em;
  line-height: 1.2;

  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
    font-size: 40px;
  }

  small {
    display: block;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: .08em;
    line-height: 1.444;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }
}
