@charset "UTF-8";

// ----------------------------------------------
mark,
.mark {
  display: inline-block;
  padding: .1em 0;
  background-color: #fcee21;
}

// ----------------------------------------------
ul,
ol {
  padding-left: 1.5em;
}

// ----------------------------------------------
.list-inline {
  margin-left: 0;

  > li {
    &:first-child {
      padding-left: 0;
    }
  }

  &.bordered {
    > li {
      padding-right: 15px;
      padding-left: 15px;
      margin-bottom: 10px;
      line-height: 1.1;
      border-right: 1px solid #999;

      &:first-child {
        border-left: 1px solid #999;
      }
    }
  }
}

// ----------------------------------------------
.list-social {
  @include media-breakpoint-up(md) {
    margin-top: 17px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }
}

// ----------------------------------------------
.list-disc {
  @extend .list-unstyled;

  li {
    position: relative;
    padding-left: 15px;

    &:before {
      position: absolute;
      top: 9px;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: #fff;
      content: "";

      @include media-breakpoint-down(md) {
        top: 7px;
        width: 7px;
        height: 7px;
      }
    }

    + li {
      margin-top: 12px;

      @include media-breakpoint-down(md) {
        margin-top: 6px;
      }
    }
  }
}

// ----------------------------------------------
.dl-list-indent {
  margin-bottom: 0;

  &,
  dd {
    overflow: hidden;
    zoom: 1;
  }

  dt,
  dd {
    display: table-cell;
    vertical-align: top;
  }

  dt {
    padding-right: 5px;
    font-weight: normal;

    img {
      display: block;
    }
  }

  dd {
    width: 10000px;
  }

  &.cleared-xs {
    @include media-breakpoint-down(sm) {
      dt {
        display: block;
      }

      dd {
        padding-top: 0;
      }
    }
  }
}
