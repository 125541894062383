@charset "UTF-8";

// sass-lint:disable brace-style
// ----------------------------------------------
.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs14-xs {
  font-size: 14px !important;

  @include media-breakpoint-down(sm) {
    font-size: 12px !important;
  }
}

// ----------------------------------------------
.mt0 {
  margin-top: 0 !important;
}

// ----------------------------------------------
.mr0 {
  margin-right: 0 !important;
}

// ----------------------------------------------
.mb0 {
  margin-bottom: 0 !important;
}

// ----------------------------------------------
.ml0 {
  margin-left: 0 !important;
}

// ----------------------------------------------
.pt0 {
  padding-top: 0 !important;
}

// ----------------------------------------------
.pr0 {
  padding-right: 0 !important;
}

// ----------------------------------------------
.pb0 {
  padding-bottom: 0 !important;
}

// ----------------------------------------------
.pl0 {
  padding-left: 0 !important;
}

// sass-lint:enable brace-style

// ----------------------------------------------
.pull-none-xs {
  @include media-breakpoint-down(sm) {
    float: none !important;
  }
}

.pull-none-sm {
  @include media-breakpoint-down(sm) {
    float: none !important;
  }
}

.pull-none-md {
  @include media-breakpoint-down(md) {
    float: none !important;
  }
}

// ----------------------------------------------
.rotate-90-xs {
  @include media-breakpoint-down(sm) {
    transform: rotate(90deg);
  }
}

.rotate-90-sm {
  @include media-breakpoint-down(sm) {
    transform: rotate(90deg);
  }
}
