@charset "UTF-8";

// ----------------------------------------------
@mixin row-variant($parent, $grid-gutter-width) {
  #{$parent} {
    margin-right: -$grid-gutter-width;
    margin-left: -$grid-gutter-width;

    > div,
    > li {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
    }
  }
}

// ----------------------------------------------
@include row-variant(".row-0", 0);
@include row-variant(".row-5", 5px);
@include row-variant(".row-10", 10px);

// ---------------------------------------
@include media-breakpoint-up(xl) {
  @include row-variant(".row-24", 24px);
  @include row-variant(".row-14", 14px);
}

// ----------------------------------------------
@include media-breakpoint-down(sm) {
  @include row-variant(".row-5-xs", 5px);
}

// ----------------------------------------------
.container {
  max-width: 1110px;
}

// --------------------------------------------------
.container-fluid-0 {
  padding-right: 0;
  padding-left: 0;
}

// ----------------------------------------------
.container-img-parallax {
  max-width: 1920px;
  margin-top: 200px;
  margin-bottom: 100px;

  @include media-breakpoint-down(sm) {
    margin-top: 100px;
    margin-bottom: 150px;
  }
}
