@charset "UTF-8";

// ----------------------------------------------
@keyframes zoom-up {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.15);
  }
}

// ----------------------------------------------
.swiper-fv {
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(198, 198, 198, 0%) 76%, #4a4a4a 110%);
    opacity: .4;
    z-index: 2;
    content: "";
  }

  .swiper-slide {
    height: 100vh;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      @media (min-width: 1800px) {
        object-position: top;
      }
    }
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active,
  .swiper-slide-prev {
    img {
      animation: zoom-up 10s linear 0s 1 normal both;
    }
  }

  .swiper-pagination {
    position: absolute;
    right: -13px;
    bottom: 47%;
    left: auto;
    width: fit-content;
    transform: rotate(90deg);

    @include media-breakpoint-down(md) {
      right: -33px;
    }

    .swiper-pagination-bullet {
      opacity: 1;
      width: 7px;
      height: 7px;
      margin: 0 9px;
      background-color: #fff !important;
    }
  }

  svg {
    z-index: 10;
    overflow: visible;

    circle {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: stroke 80s linear;
      animation-play-state: paused;
    }
  }

  .swiper-pagination-bullet-active {
    svg {
      display: block !important;

      circle {
        animation-play-state: running;
      }
    }
  }

  @keyframes stroke {
    to {
      stroke-dashoffset: 0;
    }
  }
}

// ----------------------------------------------
.swiper-pagination {
  position: static;
}

// ----------------------------------------------
// .swiper-button-next,
// .swiper-button-prev {
//   @include transition(all $hover-transition-speed ease);
//   width: 50px;
//   height: 50px;
//   background: #ff6536;
//   border-radius: 50%;

//   &:after {
//     position: absolute;
//     top: 0;
//     right: 20px;
//     bottom: 0;
//     display: inline-block;
//     width: 12px;
//     height: 12px;
//     margin-top: auto;
//     margin-bottom: auto;
//     border-top: 2px solid #ebffef;
//     border-right: 2px solid #ebffef;
//     content: "";
//   }

//   &:hover {
//     opacity: .8;
//   }
// }

// ----------------------------------------------
// .swiper-button-prev {
//   &:after {
//     right: 17px;
//     transform: rotate(225deg);
//   }
// }

// ----------------------------------------------
// .swiper-button-next {
//   &:after {
//     left: 17px;
//     transform: rotate(45deg);
//   }
// }

// ----------------------------------------------
.swiper-nonstop-slide {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 88px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 46px;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }
}

// ----------------------------------------------
.swiper-gallery {
  position: relative;
  overflow: hidden;
  width: 100%;

  .swiper-slide {
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
      height: auto;

      &.height {
        @include media-breakpoint-up(md) {
          max-height: 90dvh;
          width: auto;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
  }
}
