@charset "UTF-8";

// ----------------------------------------------
footer {
  padding-block: 50px;
  color: rgba(237, 237, 237, 60%);
  background-color: #000;

  @include media-breakpoint-down(md) {
    padding-block: 30px;
  }

  a {
    &:hover {
      img {
        animation: none;
      }
    }
  }

  .container {
    max-width: 1110px;
  }

  .address,
  .privacy-policy {
    font-family: $font-family-cardo;
    font-size: 13px;
    letter-spacing: .06em;
  }

  .privacy-policy {
    opacity: .6;
  }

  .address {
    margin-block: 20px 10px;
    line-height: 1.615;

    @include media-breakpoint-down(md) {
      margin-block: 10px 20px;
    }
  }

  .flex-content-right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;

    @include media-breakpoint-down(md) {
      align-items: flex-start;
    }

    a {
      &:hover {
        opacity: 1;
        color: #fff;
      }
    }
  }
}

// ----------------------------------------------
// Use with grBackToTop.js
// See example: https://gitlab.com/yugo.asia/scsk.jp-sp-panzura
.page-top {
  position: fixed;// Required for grBackToTop.js
  right: 20px;
  bottom: 0;// Required for grBackToTop.js
  z-index: 999;
  width: 50px;
  height: 50px;
  transition: none;

  @include media-breakpoint-down(sm) {
    right: 0;
  }

  &:hover {
    .bg-color {
      fill: #00aaad;
    }
  }
}
