@charset "UTF-8";

// ----------------------------------------------
@mixin button-variant-hover-bright($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 25%);
  }

  &:hover {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: lighten($background, 17%);
      border-color: lighten($border, 25%);
    }
  }

  &:active,
  &.active {
    background-image: none;
  }

  .open > & {
    .dropdown-toggle {
      background-image: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  // .badge {
  //   color: $background;
  //   background-color: $color;
  // }
}

// ----------------------------------------------
@mixin button-variant-hover-inverse($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 25%);
  }

  &:hover {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $background;
    background-color: $color;
    // border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $background;
      background-color: $color;
      // border-color: darken($border, 25%);
    }
  }

  &:active,
  &.active {
    background-image: none;
  }

  .open > & {
    .dropdown-toggle {
      background-image: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      // border-color: $border;
    }
  }

  // .badge {
  //   color: $background;
  //   background-color: $color;
  // }
}

// Button
// ----------------------------------------------
// .btn-*** {
//   @include button-variant($btn-default-bg, $btn-default-border, $btn-default-color);
// }

// ----------------------------------------------
.btn-multiline {
  white-space: normal;
}

// ----------------------------------------------
.btn {
  @include transition(all $hover-transition-speed ease);
}

// ----------------------------------------------
.btn-gradient-orange-animated {
  @include button-variant(transparent, #fff, #fff);
  position: relative;
  z-index: 1;
  padding: 20px 40px;
  border: 0;
  border-radius: 0;

  &:hover,
  &:focus {
    color: #fff;
  }

  &:hover {
    box-shadow: 0 0 15px 0 rgba(255, 255, 255, 900%);

    &:after {
      opacity: 1;
    }
  }

  &:after {
    @include transition(all .3s ease);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    background-color: #ccc;
    background-image: linear-gradient(to bottom, #f29e32 0, #ef8701 100%);

    content: "";
    opacity: 0;
  }

  &,
  &:active {
    background-image: linear-gradient(to bottom, #ef8701 0, #f29e32 100%);
  }
}

// ----------------------------------------------
.btn-invert-purple {
  $letter-spacing: .11em;

  @include button-variant(#fff, #a94579, #a94579);
  padding: 15px 4em 13px 4 + $letter-spacing;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: $letter-spacing;
  background: linear-gradient(to right, #fff 50%, #a94579 50%);
  background-size: 202% 100%;
  border-radius: 3px;
  transition: background-position 400ms cubic-bezier(.76, 0, .24, 1), all ease 500ms;

  @include media-breakpoint-down(sm) {
    font-size: 11px;
  }

  &.bg-light-gray {
    background: linear-gradient(to right, #f2f2f2 50%, #a94579 50%);
    background-size: 200% 100%;
  }

  &.arrow-purple {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      right: 18px;
      bottom: 0;
      width: 14px;
      height: 3px;
      margin-top: auto;
      margin-bottom: auto;
      content: "";
      transition: all ease 500ms;
    }

    &:before {
      background: url("../img/icon/arrow-half-right-dark-pink.svg") 0 0 no-repeat;
    }

    &:after {
      background: url("../img/icon/arrow-half-right-white.svg") 0 0 no-repeat;
      opacity: 0;
    }
  }

  &:hover,
  &:focus {
    &,
    &:active {
      @include box-shadow (none);
      color: #fff !important;
      background-color: #a94579;
      background-position: -99% 0;

      &.arrow-purple {
        &:before,
        &:after {
          right: 13px;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

// ----------------------------------------------
.btn-dark-blue-hover-inverse {
  @include button-variant-hover-inverse(#052029, #052029, #fff);
  max-width: 264px;
  margin-top: 28px;
  margin-right: auto;
  margin-left: auto;
  font-size: 14px;
  border-radius: 0;

  &:focus {
    color: #fff;
    background-color: #052029;
    border: 1px solid #052029;
    outline: 0;
  }
}

// ----------------------------------------------
.btn-bordered {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 21px 40px;
  font-size: 16px;
  border-color: #fff;
  transition: .3s all ease;
  border-radius: 0;

  @include media-breakpoint-down(md) {
    padding: 18px 12px;
    font-size: 14px;
  }

  &:hover {
    color: #000;
    background-color: #fff;

    &:after {
      border-color: #000;
    }
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 34px;
    border-top: 1px solid #fff;
    content: "";
  }
}

// ----------------------------------------------
.btn-white {
  background-color: #fff;
  border-radius: 0;
  letter-spacing: .12em;
  padding: 19px 40px 22px;
  color: #000;
  font-size: 23px;
  text-align: center;
  display: block;
  border: 1px solid #fff;
  text-decoration: none;
  transition: all .2s ease-in-out;
  position: relative;

  &:hover {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;

    &:after {
      background-color: #fff;
    }
  }

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 30px;
    top: 50%;
    right: 0;
    background-color: #000;

    @include media-breakpoint-down(md) {
      width: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 25px;
    padding: 12px 30px 13px;
    font-size: 18px;
  }
}

// ----------------------------------------------
.btn-black {
  position: relative;
  display: block;
  width: 270px;
  max-width: 270px;
  padding: 15px 30px 16px;
  font-size: 19px;
  letter-spacing: .06em;
  color: #ededed;
  background-color: #292929;
  border: 1px solid #292929;
  border-radius: 0;
  transition: all .15s ease;

  @include media-breakpoint-down(md) {
    font-size: 17px;
  }

  &:hover {
    opacity: .7;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 26px;
    border-top: 1px solid #b1b1b1;
    content: "";
  }

  small {
    margin-left: 5px;
    font-size: 10px;
  }
}

// ----------------------------------------------
.btn-submit {
  margin: 50px auto 0;

  @include media-breakpoint-down(md) {
    margin-top: 25px;
  }
}
