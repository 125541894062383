@charset "UTF-8";

// ----------------------------------------------
.navbar {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #000;

  .container {
    max-width: 1366px;
    align-items: flex-start;
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-brand {
    padding: 20px 38px;
    margin: 0;

    @include media-breakpoint-down(xl) {
      padding-top: 24px;
    }

    @include media-breakpoint-down(lg) {
      padding: 22px 10px;
    }

    @include media-breakpoint-down(md) {
      padding: 10px 15px;
    }

    img {
      @include media-breakpoint-down(lg) {
        width: 110px;
      }

      @include media-breakpoint-down(md) {
        width: auto;
      }
    }

    a {
      display: block;
    }
  }

  .navbar-nav {
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      align-items: flex-start;
      margin-inline: auto 0;
    }

    .nav-item {
      padding: 28px 15px 0;

      @include media-breakpoint-down(xl) {
        padding-right: 8px;
        padding-left: 8px;
      }

      @include media-breakpoint-down(lg) {
        padding-top: 22px;
      }

      @include media-breakpoint-down(md) {
        padding: 0;
        text-align: center;
      }

      &.nav-item-tel {
        padding: 18px 12px 0 16px;
        display: flex;

        @include media-breakpoint-down(lg) {
          padding-top: 20px;
        }

        @include media-breakpoint-down(md) {
          padding: 0;
          justify-content: center;
        }
      }

      &.nav-item-contact {
        padding: 0;

        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }

      .icon-tel {
        margin-right: 10px;
      }
    }

    .nav-link {
      padding: 0 0 4px;
      font-family: $font-family-cardo;
      font-size: 17px;
      color: #fff;
      letter-spacing: .08em;
      transition: .3s all ease;
      border-bottom: 1px solid transparent;

      @include media-breakpoint-down(xl) {
        font-size: 14px;
      }

      @include media-breakpoint-down(md) {
        padding: 10px 20px;
      }

      &:hover {
        @include media-breakpoint-up(md) {
          opacity: .8;
        }
      }

      &.nav-link-icon {
        padding: 17px 15px 5px;
        font-size: 12px;

        @include media-breakpoint-down(lg) {
          padding-top: 10px;
          padding-right: 4px;
          padding-left: 4px;
          font-size: 11px;
        }

        @include media-breakpoint-down(md) {
          padding: 10px 15px;
        }

        .icon {
          display: block;
          margin-inline: auto;
          margin-bottom: 8px;

          @include media-breakpoint-down(lg) {
            width: 20px;
            height: 25px;
            object-fit: contain;
          }
        }

        &.active {
          border-bottom: 0;
        }
      }

      &.nav-link-data {
        color: #fff;
        background-color: #777;
      }

      &.nav-link-visitor {
        color: #000;
        background-color: #fff;
      }

      &.tel {
        padding-bottom: 0;
        font-size: 29px;

        @include media-breakpoint-down(xl) {
          font-size: 27px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 18px;
        }

        @include media-breakpoint-down(md) {
          padding: 15px 0;
        }
      }

      &.active {
        @include media-breakpoint-up(md) {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
}

// ----------------------------------------------
.js-header {
  &.is-scrolled {
    position: fixed;
    top: -200px;
  }

  &.is-transition {
    @include transition(top .3s ease-in-out);
    will-change: top;
  }

  &.is-show {
    top: 0;
  }
}

// ----------------------------------------------
.menu-trigger {
  position: absolute;
  top: 14px;
  right: 15px;
  z-index: 1000;
  width: 40px;
  height: 32px;
  transition: all .3s;

  @include media-breakpoint-down(sm) {
    display: inline-block;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    border: 0;
    outline: 0;
    box-shadow: none !important;
  }

  .icon-bar {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    transition: all .3s;

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 14px;
    }

    &:nth-of-type(3) {
      bottom: 0;
    }

    & + .icon-bar {
      margin-top: 0;
    }
  }

  &[aria-expanded="true"] {
    &,
    &:focus {
      box-shadow: none;
    }

    .icon-bar {
      &:nth-of-type(1) {
        transform: translateY(14px) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(-14px) rotate(45deg);
      }
    }
  }
}

// ----------------------------------------------
.collapse-parent {
  $space: 6px;

  position: relative;

  .collapse-child {
    @include box-shadow(0 6px 12px rgba(0, 0, 0, .175));
    background-color: #fff;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: $zindex-dropdown;
    }

    @include media-breakpoint-down(md) {
      @include box-shadow(0 3px 6px rgba(0, 0, 0, .175));
    }

    ul {
      padding-top: $space;
      padding-bottom: $space;
    }
  }

  a {
    display: block;
    padding: $space 15px;
  }

  .collapsing {
    transition: height .2s ease;
  }

  // [data-toggle-touch] {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  // }
}
