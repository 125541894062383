@charset "UTF-8";

// ----------------------------------------------
.table {
  @include media-breakpoint-up(md) {
    table-layout: fixed;
  }

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        line-height: 1.428571429;
      }
    }
  }

  > thead {
    > tr {
      > th,
      > td {
        border-bottom-width: 1px;
      }
    }
  }

  > tbody {
    > tr {
      > th,
      > td {
        word-break: break-all;
      }
    }
  }

  > :not(:first-child) {
    border-top: 0;
  }

  th {
    font-weight: normal;
  }

  &.table-text-center {
    th,
    td {
      text-align: center;
    }
  }

  &.table-text-right {
    th,
    td {
      text-align: right;
    }
  }
}

// ----------------------------------------------
.table-responsive {
  @include media-breakpoint-down(md) {
    > .table {
      > tbody {
        > tr {
          :first-child {
            > th,
            > td {
              border-top: 0;
            }
          }
        }
      }
    }
  }
}

// ----------------------------------------------
// mobile table
@include media-breakpoint-down(md) {
  .table-collapsed-md {
    display: block;
    border: 0 !important;

    > :not(caption) > * {
      border-width: .5px 0;
    }

    > tbody {
      display: block;

      > tr {
        > th,
        > td {
          display: block;
          width: auto;
          padding: 10px !important;
        }

        > th {
          @include clearfix();
          position: relative;
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
      }
    }
  }
}

// ----------------------------------------------
.table-cell {
  display: table;
  width: 100%;

  > .cell {
    display: table-cell;
  }

  &.bordered {
    margin-bottom: 30px;

    .cell {
      padding: 0 10px;
      margin-bottom: 35px;
      border-left: 1px solid #ccc;
    }
  }

  &.cleared-md {
    @include media-breakpoint-down(md) {
      &,
      > .cell {
        display: block;
        width: auto;
      }
    }
  }

  &.cleared-lg {
    @include media-breakpoint-down(lg) {
      &,
      > .cell {
        display: block;
        width: auto;
      }
    }
  }
}

// ----------------------------------------------
.table-detail {
  > tbody {
    > tr {
      > th,
      > td {
        padding: 10px 7px 9px;
        line-height: 1.786;
        letter-spacing: .08em;
        border-color: #b2b2b2;

        @include media-breakpoint-down(md) {
          padding: 6px 4px;
          line-height: 1.5;
        }
      }

      > th {
        width: 94px;

        @include media-breakpoint-down(md) {
          width: 70px;
        }
      }
    }
  }

  .link {
    margin-left: 10px;
    font-size: 10px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }
}

// ----------------------------------------------
.table-border-white {
  margin-block: 50px 0;
  border-top: 1px solid #fff;

  @include media-breakpoint-down(md) {
    margin-top: 30px;
  }

  > tbody {
    > tr {
      > th,
      > td {
        padding: 10px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: .08em;
        line-height: 2;

        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 1.8;
        }

        a {
          display: inline-block;
          font-size: 11px;

          svg {
            vertical-align: middle;
          }
        }
      }

      > th {
        width: 110px;
        border-right: 1px solid #fff;
        text-align: center;
        vertical-align: middle;
      }

      > td {
        padding-left: 46px;

        @include media-breakpoint-down(md) {
          padding-left: 20px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

// ----------------------------------------------
.table-form {
  width: 100%;

  > tbody {
    > tr {
      display: block;

      + tr {
        margin-top: 43px;

        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }
      }

      > th,
      > td {
        position: relative;
        display: block;
        font-size: 14px;

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }

      > th {
        margin-bottom: 12px;
        font-weight: normal;
        letter-spacing: .1em;

        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
